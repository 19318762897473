body
{
  margin:0;
  overflow-x: hidden;
  background-color: rgba(205,205,205,1);
}



.icon
{
  display:block;
  margin: 1vw;
  margin-bottom: max(1vw, 10px);
  width:max(2vw, 3vh);
  height:max(2vw, 3vh);
  filter:invert(10%);
  transition: width 0.25s ease-in-out, height 0.25s ease-in-out;
}

.contentIcon:hover
{
  filter: invert(66%) sepia(83%) saturate(3937%) hue-rotate(157deg) brightness(88%) contrast(101%);
}
.splashIcon:hover
{
  filter: invert(59%) sepia(92%) saturate(3797%) hue-rotate(328deg) brightness(94%) contrast(95%);
}
